<template>
	<div class="mainSliderContainer">
		<VueSlickCarousel class="mainSlider" :class="additionalClass" v-bind="slickSettings" @init="onInitCarousel" @afterChange="afterChange" @beforeChange="beforeChange" v-if="slider.length > 1">
			<template v-for="slide in slider">
				<div class="mainSlider__item" :class="slide.class" :style="{'background-color': slide.background}">
					<a class="mainSlider--nextSlide" @click="nextSlide"></a>
					<div class="container">
						<div class="mainSlider__left">
							<div class="mainSlider__content">
								<p class="mainSlider--title" v-html="slide.title"></p>
								<div class="mainSlider--desc" v-html="slide.desc"></div>
								<router-link class="mainSlider--link" v-if="slide.linkHref" :to="slide.linkHref">
									<template v-if="slide.linkTitle">
										{{slide.linkTitle}}
									</template>
									<template v-else>
										Подробнее
									</template>	
								</router-link>
							</div>
						</div>
						<div class="mainSlider__right">
							<img v-if="slide.image" v-lazy="slide.image" :class="slide.animatedImageClass" alt="">
							<div v-if="slide.animatedImage" :class="slide.animatedImageClass">
								<div v-html="slide.animatedImage"></div>
								<div v-html="slide.animatedImage" class="shadow"></div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #customPaging="page">
				<div class="dot">
					<div class="dot--progress"></div>
				</div>
			</template>
		</VueSlickCarousel>
		<div class="mainSlider oneSlide" :class="additionalClass" v-else>
			<template v-for="slide in slider">
				<div class="mainSlider__item" :class="slide.class" :style="{'background-color': slide.background}">
					<a class="mainSlider--nextSlide" @click="nextSlide"></a>
					<div class="container">
						<div class="mainSlider__left">
							<div class="mainSlider__content">
								<p class="mainSlider--title" v-html="slide.title"></p>
								<p class="mainSlider--desc" v-html="slide.desc"></p>
								<router-link class="mainSlider--link" v-if="slide.linkHref" :to="slide.linkHref">
									<template v-if="slide.linkTitle">
										{{slide.linkTitle}}
									</template>
									<template v-else>
										Подробнее
									</template>	
								</router-link>
							</div>
						</div>
						<div class="mainSlider__right">
							<img v-if="slide.image" v-lazy="slide.image" :class="slide.animatedImageClass" alt="">
							<div v-if="slide.animatedImage" :class="slide.animatedImageClass">
								<div v-html="slide.animatedImage"></div>
								<div v-html="slide.animatedImage" class="shadow"></div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/MainSlider'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['slider', 'additionalClass'],
		data: () => ({
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'mainSlider__dots container',
				infinite: true
			},
			isPause: false,
			percentTime: 0,
			time: 5,
			tick: null,
			dotsProgress: null
		}),
		methods: {
			onInitCarousel: function() {
				var comp = this;
				setTimeout(function(){
					comp.dotsProgress = comp.$vnode.elm.getElementsByClassName('dot--progress');
					if(comp.tick){
						clearTimeout(comp.tick);
					}
					comp.isPause = false;
					comp.tick = setInterval(function(){
						if (comp.isPause === false) {
							comp.percentTime = comp.percentTime + (1 / (comp.time + 0.1));
							comp.dotsProgress.forEach(function(item) {
								item.style.width = comp.percentTime + "%";
							});
							if(comp.percentTime >= 100){
								comp.$children[0].next();
								comp.percentTime = 0;
							}
						}
					}, 10);
				});
			},
			beforeChange: function(){
				this.isPause = true;
				this.percentTime = 0;
				this.dotsProgress.forEach(function(item) {
					item.style.width = "0%";
				});
			},
			afterChange: function(){
				this.percentTime = 0;
				this.isPause = false;
			},
			nextSlide: function(){
				this.$children[0].next();
			}
		},
		beforeDestroy: function () {
			var comp = this;
			if(comp.tick){
				clearTimeout(comp.tick);
			}
		},
		components: {
			VueSlickCarousel
		}
	}
</script>