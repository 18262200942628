<template>
	<div class="content">
		<MainSlider :slider="slider" :additionalClass="'slide404 margin'"></MainSlider>
		<WithLeftMenu :blocks="withLeftMenuContent"></WithLeftMenu>
		<FeedBack></FeedBack>
	</div>
</template>

<script>
	import	MainSlider from '@/components/app/MainSlider'
	import	WithLeftMenu from '@/components/app/WithLeftMenu'
	import	FeedBack from '@/components/app/forms/FeedBack'

	export default {
		metaInfo: {
			title: 'Разработка и продвижение сайтов | Веб-студия «Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Полный цикл развития интернет-магазинов и корпоративных порталов: аналитика, разработка, SEO, техническая поддержка и сопровождение сайтов.' },
				{ vmid: 'og:title', property: 'og:title', content: 'Разработка и продвижение сайтов | Веб-студия «Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Полный цикл развития интернет-магазинов и корпоративных порталов: аналитика, разработка, SEO, техническая поддержка и сопровождение сайтов.' },
				{ name: 'prerender-status-code', content: '404' }
			],
		},
		data: () => ({
			slider: [
				{
					title: 'Упс! Что-то пошло не так',
					desc: 'Страница не найдена! Возможно вы ввели не верный URL-адрес, либо эта страница поменяла место жительства:)',
					image: require(`@/assets/img/banner/404.png`),
					animatedImageClass: 'neonLogo',
					class: 'whiteText',
					background: '#192029'
				}
			],
			withLeftMenuContent: [
				{
					component: 'siteMap',
					name: 'Карта сайта',
				}
			]
		}),
		name: 'page404',
		components: {
			MainSlider,
			WithLeftMenu,
			FeedBack
		}
	}
</script>
